import { cookieSetItemInfinity, cookieGetItem, cookieGetItemStr } from './cookie';

export const getIsWechatBrowser = () => {
  return navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == 'micromessenger';
};

export const getUrlProtocol = () => {
  return window.location.protocol + '//';
};

export const getUrlProtocolHost = () => {
  return getUrlProtocol() + window.location.host;
};

export const getHost = () => {
  return window.location.host;
};

export const getFullUrl = () => {
  return window.location.href;
};

export const getPath = () => {
  try {
    return new URL(getFullUrl()).pathname;
  } catch (e) {
    return '';
  }
};

export const getUserAgent = () => {
  return navigator.userAgent;
};

export const getReferrer = () => {
  return document.referrer;
};

export const getReferrerHost = () => {
  try {
    const host = document.referrer.split('/')[2];
    return host;
  } catch (e) {
    return '';
  }
};

export const GetQueryValue = (queryName) => {
  var query = decodeURI(window.location.search.substring(1));
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (pair[0] == queryName) {
      return pair[1];
    }
  }
  return null;
};

export const isEntryPage = () => {
  try {
    if (cookieGetItemStr('visit_time')) {
      const last = parseInt(cookieGetItemStr('visit_time'));
      const current = new Date().getTime();
      const isLt30 = (current - last) / (1000 * 60);
      cookieSetItemInfinity('visit_time', new Date().getTime());
      if (isLt30 > 30) {
        return true;
      } else if (getReferrer().indexOf('pzacademy.com') != -1 || !getReferrer()) {
        return true;
      } else {
        return false;
      }
    } else {
      cookieSetItemInfinity('visit_time', new Date().getTime());
      return true;
    }
  } catch (e) {
    return false;
  }
};

export const getUriHref = () => {
  const urlProtocolHost = getUrlProtocolHost();
  return window.location.href.substring(urlProtocolHost.length);
};

export const getIframeParamValue = (paramName) => {
  const url = window.location.search.substring(1);
  const qArray = url.split('&');
  for (let i = 0; i < qArray.length; i++) {
    const pArr = qArray[i].split('=');
    if (pArr[0] == paramName) return pArr[1];
  }
};

export const getIsInIframe = () => {
  return window.parent != window;
};

export const getIsMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

export const isBrowserSupported = () => {
  const isFirefox = typeof InstallTrigger !== 'undefined';
  const isSafari = navigator.vendor.indexOf('Apple') == 0 && /\sSafari\//.test(navigator.userAgent); // true or false
  const isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
  const is360 = window.external && window.external.GetRunPath;
  const isLB = navigator.userAgent.toLowerCase().indexOf('lbbrowser') != -1;
  const isMaxthon = navigator.userAgent.toLowerCase().indexOf('maxthon') != -1;
  const isMobile = getIsMobile();
  const isEdge = /Edge\/\d./i.test(navigator.userAgent);
  const isIE =
    document.documentMode ||
    /MSIE 9/i.test(navigator.userAgent) ||
    /rv:11.0/i.test(navigator.userAgent) ||
    /MSIE 10/i.test(navigator.userAgent);
  return (
    (isChrome || isMobile || isEdge || isSafari) &&
    !(is360 || isLB || isMaxthon || isIE || isFirefox)
  );
};
