import $ from 'jquery';

import { cookieGetItem, cookieGetItemStr, cookieSetItemInfinity } from './cookie';
import { USER_INFO } from './constants';
import {
  getFullUrl,
  getUserAgent,
  getReferrer,
  getReferrerHost,
  isEntryPage,
  GetQueryValue,
  getPath,
} from './browser';

(() => {
  if (!cookieGetItemStr('visitor_id')) {
    const visitorId = $('#visitor_id').val();
    cookieSetItemInfinity('visitor_id', visitorId);
    cookieSetItemInfinity('is_new_visitor', true);
  } else {
    cookieSetItemInfinity('is_new_visitor', false);
  }
  const userInfo = cookieGetItem(USER_INFO);
  const fromDomain = getReferrerHost();
  const fromUrl = getReferrer();
  const studentId = userInfo ? userInfo.studentId : '';
  const vendorId = GetQueryValue('vendorid') || cookieGetItem('vendorId');

  $.ajax({
    url: `/api/nuxt/tracking/www/access`,
    type: 'POST',
    dataType: 'json',
    data: JSON.stringify({
      visitorMap: {
        fromDomain: fromDomain,
        fromEngine: '',
        fromKeyword: '',
        fromType: '',
        fromUrl: fromUrl,
        isEntrance: isEntryPage(),
        isExit: '',
        targetPath: getPath(),
        targetUrl: getFullUrl(),
        vendorId: vendorId,
        visitorAgent: getUserAgent(),
        visitorBrowser: '',
        visitorDeviceId: '',
        visitorId: cookieGetItemStr('visitor_id'),
        visitorIp: '',
        visitorIsNew: cookieGetItemStr('is_new_visitor'),
        visitorOs: '',
        visitorStayTime: '',
        visitorStudentId: studentId,
      },
    }),
    contentType: 'application/json',
  });
  // $.post(
  //   `/api/nuxt/tracking/www/access`,

  // );
})();
