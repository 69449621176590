import Cookies from 'js-cookie';
import { SERVER_ROOT_DOMAIN } from './constants';

const getDomain = () => {
  if (-1 !== window.location.host.indexOf(SERVER_ROOT_DOMAIN)) {
    return SERVER_ROOT_DOMAIN;
  }
  return null;
};

export const cookieSetItemInfinity = (name, content) => {
  if (!name) return;
  Cookies.set(name, content, { expires: 100 * 365, path: '/', domain: getDomain() });
};

export const cookieSetItem = (name, content) => {
  if (!name) return;
  Cookies.set(name, content, { expires: null, path: '/', domain: getDomain() });
};

export const cookieGetItem = (name) => {
  if (!name) return;
  if (cookieHasItem(name)) {
    return JSON.parse(Cookies.get(name));
  }
  return null;
};

export const cookieGetItemStr = (name) => {
  if (!name) return;
  if (cookieHasItem(name)) {
    return Cookies.get(name);
  }
  return null;
};

export const cookieRemoveItem = (name) => {
  if (!name) return;
  Cookies.remove(name, { path: '/', domain: getDomain() });
};

export const cookieHasItem = (name) => {
  if (!name) return false;
  return Boolean(Cookies.get(name)) || false;
};
